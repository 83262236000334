import React from 'react';
import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h1> Hello World. </h1>
        <p> Hosting a website is very easy with GitHub Pages! </p>
        <p> Testing the custom domain</p>
      </header>
    </div>
  );
}

export default App;
